import { Provider } from "react-redux";

import Store from "./redux/stores";
import ProviderConfig from "./utilities/ProviderConfig";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <Provider store={Store}>
      <ProviderConfig />
    </Provider>
  );
};

export default App;
