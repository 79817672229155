const initialState = {
  listRoom: [],
  detailChat: {
    chat_room: {},
    chats: [],
  },
  isLoading: false,
  isError: false,
  errorMessage: "",
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LIST_ROOM_START":
      return { ...state, isLoading: true };
    case "GET_LIST_ROOM_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listRoom: action.payload,
      };
    case "GET_LIST_ROOM_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listRoom: [],
        errorMessage: action.payload,
      };
    case "GET_DETAIL_CHAT_START":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DETAIL_CHAT_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        detailChat: action.payload,
      };
    case "GET_DETAIL_CHAT_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        detailChat: {
          chat_room: {},
          chats: [],
        },
        errorMessage: action.payload,
      };
    case "SEND_CHAT_START":
      return { ...state };
    case "SEND_CHAT_SUCCESS":
      return {
        ...state,
        isError: false,
      };
    case "SEND_CHAT_ERROR":
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
