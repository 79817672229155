const hostname = window && window.location && window.location.hostname;
const base_url = "/";
const apikey_dev = "c8029f89-b1b6-4e74-8a8f-3b6b2e5e3784";
const apikey_prod = "a37f1969-5c75-40b7-ae8d-ccd91aa30fe9";

let base_api;
let external_link;
let api_key;

const config = {
  base_api: {
    dirty: "",
    beta: "https://beta.api.apps.parto.id/",
    alpha: "",
    north: "",
    prod: "https://apps.parto.id/",
  },
};

switch (true) {
  // case /dirty/.test(hostname):
  //   base_api = config.base_api.dirty;
  //   api_key = apikey_dev;
  //   break;

  // case /alpha/.test(hostname):
  //   base_api = config.base_api.alpha;
  //   api_key = apikey_dev;
  //   break;

  case /dev.parto.id/.test(hostname):
    base_api = config.base_api.beta;
    api_key = apikey_dev;
    break;

  case /lkpp-v2.parto.id/.test(hostname):
    base_api = config.base_api.prod;
    api_key = apikey_prod;
    break;

  default:
    base_api = config.base_api.beta;
    api_key = apikey_dev;
    break;
}

export { base_url, base_api, api_key, external_link };
