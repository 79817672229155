import axios from "axios";

import { base_api } from "../../services/api";

export const getListCart = (token, idNego) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_CART_START" });
      const axiosConfigGetListCart = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responseGetListCart = await axios.get(
        `${base_api}transactions/get-cart-items/${idNego}`,
        axiosConfigGetListCart
      );
      dispatch({
        type: "GET_LIST_CART_SUCCESS",
        payload: responseGetListCart.data.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_LIST_CART_FAILED",
        payload: error.message,
      });
    }
  };
};

export const addToCart = (idProduct, qty, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ADD_TO_CART_START" });
      const formData = new FormData();
      formData.append("qty", qty);
      const axiosConfigAddToCart = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": "multipart/form-data",
      };

      const responseAddToCart = await axios.put(
        `${base_api}b2g/transaction/add-to-cart/${idProduct}`,
        formData,
        axiosConfigAddToCart
      );
      dispatch({
        type: "ADD_TO_CART_SUCCESS",
        payload: responseAddToCart.data.message,
      });
    } catch (error) {
      dispatch({
        type: "ADD_TO_CART_FAILED",
        payload: error.message,
      });
    }
  };
};

export const deleteCartItem = (token, idCart, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "DELETE_CART_START" });

      const axiosConfigDeleteCart = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const responseDeleteCart = await axios.delete(
        `${base_api}b2g/transaction/delete-cart-item/${idCart}`,
        axiosConfigDeleteCart
      );

      if (responseDeleteCart.data.status && callback) {
        callback();
      }
      dispatch({
        type: "DELETE_CART_DONE",
        payload: {
          isErrorDelete: !responseDeleteCart.data.status,
        },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_CART_DONE",
        payload: {
          isErrorDelete: true,
        },
      });
    }
  };
};

export const updateStatusCekItem = (token, idCart, status, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_CHECK_CART_START" });

      const formData = new FormData();
      formData.append("id_cart", idCart);
      formData.append("status", status);

      const axiosConfigCheckCart = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const responseCheckCart = await axios.put(
        `${base_api}nego/update-status-cek`,
        formData,
        axiosConfigCheckCart
      );

      if (responseCheckCart.data.status && callback) {
        callback();
      }
      dispatch({
        type: "UPDATE_CHECK_CART_DONE",
        payload: {
          isError: !responseCheckCart.data.status,
          message: responseCheckCart.data.message,
        },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CHECK_CART_DONE",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const updateStatusCekStore = (token, idStore, status, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_CHECK_CART_START" });

      const formData = new FormData();
      formData.append("id_reseller", idStore);
      formData.append("status", status);

      const axiosConfigCheckCart = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const responseCheckCart = await axios.put(
        `${base_api}nego/change-all-status`,
        formData,
        axiosConfigCheckCart
      );

      if (responseCheckCart.data.status && callback) {
        callback();
      }
      dispatch({
        type: "UPDATE_CHECK_CART_DONE",
        payload: {
          isError: !responseCheckCart.data.status,
          message: responseCheckCart.data.message,
        },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_CHECK_CART_DONE",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};
