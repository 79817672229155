const initialState = {
  dataCart: [],
  isLoading: false,
  isError: false,
  isErrorCheckCart: false,
  isErrorDeleteCart: false,
  errorMessage: "",
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LIST_CART_START":
      return {
        ...state,
        dataCart: [],
        isLoading: true,
      };
    case "GET_LIST_CART_SUCCESS":
      return {
        ...state,
        dataCart: action.payload,
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_LIST_CART_FAILED":
      return {
        ...state,
        dataCart: [],
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "ADD_TO_CART_START":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_TO_CART_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: action.payload,
      };
    case "ADD_TO_CART_FAILED":
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "DELETE_CART_START":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_CART_DONE":
      return {
        ...state,
        isLoading: false,
        isErrorDeleteCart: action.payload.isErrorDelete,
      };
    case "UPDATE_CHECK_CART_START":
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case "UPDATE_CHECK_CART_DONE":
      return {
        ...state,
        isLoading: false,
        isErrorCheckCart: action.payload.isError,
        errorMessage: action.payload.message,
      };
    default:
      return state;
  }
}
