import { ConfigProvider, Spin } from "antd";
import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import BaseLayout from "../layouts";
import Renegosiation from "../pages/TransactionPage/renegosiation";
import { decodeBase64 } from "../redux/actions/authAction";
import { setCategoryProduct } from "../redux/actions/productsAction";
import PrivateRoutes from "./PrivateRoutes";

const CartPage = lazy(() => import("../pages/CartPage"));
const NegoDetailPage = lazy(() =>
  import("../pages/TransactionPage/detailNego")
);
const NotFoundPage = lazy(() => import("../pages/NotFound"));
const PaymentProcessPage = lazy(() => import("../pages/PaymentPage"));
const ProductDetailPage = lazy(() =>
  import("../pages/ProductsPage/detailProduct")
);
const TokoDetailPage = lazy(() => import("../pages/ProductsPage/detailStore"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));

const InfoToko = lazy(() => import("../pages/ProductsPage/storeInfo"));

const ChatPage = lazy(() => import("../pages/ChatPage"));

const TransactionPage = lazy(() => import("../pages/TransactionPage"));
const TransactionProcessDetailPage = lazy(() =>
  import("../pages/TransactionPage/detailTransactionProcess")
);
const ValidatePage = lazy(() => import("../pages/ValidatePage"));

const ProviderConfig = () => {
  const isValidated = useSelector((state) => state.auth.isValidated);
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const fromCategory = urlParams.get("f");
    if (fromCategory) {
      dispatch(setCategoryProduct(fromCategory));
    }

    const nonce = urlParams.get("amp;nonce") || urlParams.get("nonce");
    if (!isValidated) {
      if (nonce) {
        dispatch(decodeBase64(nonce));
      }
    }
    // eslint-disable-next-line
  }, [isValidated]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            algorithm: true,
            colorText: "#2C3238",
            headerPadding: 0,
            colorIconHover: "#208B6A",
            colorPrimaryTextHover: "#208B6A",
          },
        },
        token: {
          colorPrimary: "#208B6A",
          fontFamily: "Montserrat",
        },
      }}
    >
      <Suspense
        fallback={
          <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] top-0">
            <Spin tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        }
      >
        <Router>
          <Routes>
            <Route path="/" element={<BaseLayout />}>
              <Route index element={<ValidatePage />} />
              <Route
                path="produk"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <ProductsPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="produk/detail/:idProduk"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <ProductDetailPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="toko/detail/:idToko"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <TokoDetailPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="toko/info/:idToko"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <InfoToko />
                  </PrivateRoutes>
                }
              />
              <Route
                path="keranjang"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <CartPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="chat"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <ChatPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="transaksi"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <TransactionPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="transaksi/detail-nego/:idSessionNego"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <NegoDetailPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="transaksi/renegotiation/:idSessionNego"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <Renegosiation />
                  </PrivateRoutes>
                }
              />
              <Route
                path="transaksi/detail-transaction-process/:idTransaction"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <TransactionProcessDetailPage />
                  </PrivateRoutes>
                }
              />
              <Route
                path="proses-pembayaran"
                element={
                  <PrivateRoutes isValidated={isValidated}>
                    <PaymentProcessPage />
                  </PrivateRoutes>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </ConfigProvider>
  );
};

export default ProviderConfig;
