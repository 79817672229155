import {
  MessageOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Popover } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import LogoPartoHorizontal from "../assets/img/img-logo-parto-horizontal-master-color.png";
import LogoPartoVertical from "../assets/img/img-logo-parto-vertical.png";
import { getListCart } from "../redux/actions/cartAction";
import { formatRupiah } from "../utilities/utils";

function Header(props) {
  const location = useLocation();

  const [isFetchDataCartReady, setIsFetchDataCartReady] = useState(false);
  const [isImageProductError, setIsImageProductError] = useState(false);

  useEffect(() => {
    const fetchDataCart = async () => {
      if (props.isValidated) await props.getListCart(props.accessToken, "0");

      setIsFetchDataCartReady(true);
    };

    fetchDataCart();
  }, [props.isValidated]);

  function handleImageProductError() {
    setIsImageProductError(true);
  }

  return (
    isFetchDataCartReady && (
      <div className="h-full w-full border-b-2">
        <div className="flex flex-row h-full items-center justify-between mx-4 md:mx-[4vw]">
          <Link to="/">
            <img
              src={LogoPartoHorizontal}
              alt="Logo Parto"
              className="min-w-24 max-w-32 w-[12vw] mb-1"
            />
          </Link>
          <div className="flex gap-x-3">
            <Link to="/chat">
              <Button>
                <div className="flex gap-2">
                  <MessageOutlined className="text-lg" />
                  <div className="hidden md:block">Chat</div>
                </div>
              </Button>
            </Link>

            <Link to="/transaksi">
              <Button>
                <div className="flex gap-2">
                  <ShoppingOutlined className="text-lg" />
                  <div className="hidden md:block">Transaksi</div>
                </div>
              </Button>
            </Link>

            <Popover
              title={
                <p className="font-bold text-base">
                  Keranjang{" "}
                  <span className="font-normal text-gray-400">
                    (
                    {isFetchDataCartReady && props.dataCart?.length != null
                      ? props.dataCart?.length
                      : "0"}
                    )
                  </span>
                </p>
              }
              content={
                <>
                  <div className="flex flex-col gap-y-5">
                    {props.dataCart?.slice(0, 3).map((product, id) => (
                      <div key={id} className="grid grid-cols-3 gap-4 w-full">
                        <div className="col-span-2 flex w-full">
                          {!isImageProductError ? (
                            <img
                              alt="Gambar Produk"
                              className="rounded-lg h-12 mr-2 shadow-sm w-12"
                              src={product.gambar}
                              onError={handleImageProductError}
                            />
                          ) : (
                            <img
                              alt="Gambar Produk"
                              className="border border-gray-200 rounded-lg h-12 mr-2 shadow-sm w-12"
                              src={LogoPartoVertical}
                            />
                          )}
                          <p className="overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                            {product.nama_produk}
                          </p>
                        </div>
                        <p className="font-semibold text-right w-full">
                          {product.qty} x{" "}
                          {formatRupiah(Math.round(product.harga))}
                        </p>
                      </div>
                    ))}
                    <Link to="/keranjang">
                      <Button className="bg-[#208B6A] text-[#FAFAFA] w-full">
                        Lihat Keranjang
                      </Button>
                    </Link>
                  </div>
                </>
              }
              overlayStyle={{
                width: "37%",
              }}
              placement="bottomRight"
            >
              <Link to="/keranjang">
                <Badge
                  count={isFetchDataCartReady && props.dataCart?.length}
                  overflowCount={99}
                  size="small"
                >
                  <Button>
                    <div className="flex gap-2">
                      <ShoppingCartOutlined className="text-lg" />
                      <div className="hidden md:block">Keranjang</div>
                    </div>
                  </Button>
                </Badge>
              </Link>
            </Popover>
          </div>
        </div>
        {!(location.pathname === "/chat") && (
          <div className="hidden bg-[#04493B] h-10 w-full md:flex flex-row-reverse gap-x-5 items-center px-[50px] text-end">
            <p
              className="hover:text-white hover:cursor-pointer text-sm text-gray-200"
              onClick={() => {
                window.open(" https://tokodaring.lkpp.go.id/", "_blank");
              }}
            >
              Tentang Toko Daring
            </p>
            <p
              className="hover:text-white hover:cursor-pointer text-sm text-gray-200"
              onClick={() => {
                window.open("https://linktr.ee/panduanparto", "_blank");
              }}
            >
              Panduan
            </p>
          </div>
        )}
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.auth.resultDecode.accessToken,
  isValidated: state.auth.isValidated,
  dataCart: state.cart.dataCart,
});

const mapDispatchToProps = (dispatch) => ({
  getListCart: (token, idNego) => dispatch(getListCart(token, idNego)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
