import { combineReducers } from "redux";

import authReducer from "./authReducer";
import productsReducer from "./productsReducer";
import paymentReducer from "./paymentReducer";
import transactionsReducer from "./transactionsReducer";
import cartReducer from "./cartReducer";
import chatReducer from "./chatReducer";

export default combineReducers({
  auth: authReducer,
  cart: cartReducer,
  chat: chatReducer,
  products: productsReducer,
  payment: paymentReducer,
  transactions: transactionsReducer,
});
