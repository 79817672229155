const initialState = {
  dataCheckout: {},
  defaultPurchase: {},
  isLoading: false,
  isError: false,
  errorMessage: "",
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_DEFAULT_PURCHASE_START":
      return { ...state, isLoading: true };
    case "GET_DEFAULT_PURCHASE_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        defaultPurchase: action.payload,
      };
    case "GET_DEFAULT_PURCHASE_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        defaultPurchase: {},
        errorMessage: action.payload,
      };
    case "GET_LIST_CHECKOUT_START":
      return { ...state, isLoading: true };
    case "GET_LIST_CHECKOUT_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        dataCheckout: action.payload,
      };
    case "GET_LIST_CHECKOUT_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        dataCheckout: {},
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
