const initialState = {
  isValidated: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
  resultDecode: {},
  resultDecodeAccessToken: {},
  sub: "",
  tokenNonce: "",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "VALIDATE_START":
      return { ...state, isLoading: true };
    case "VALIDATE_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        isValidated: action.payload,
      };
    case "VALIDATE_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        isValidated: false,
        errorMessage: action.payload,
      };
    case "DECODE_START":
      return { ...state, isLoading: true };
    case "DECODE_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        resultDecode: action.payload,
      };
    case "DECODE_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
        resultDecode: {},
      };
    case "DECODE_ACCESS_TOKEN":
      return {
        ...state,
        resultDecodeAccessToken: action.payload,
        sub: action.payload.sub,
      };
    case "STORE_TOKEN_NONCE":
      return {
        ...state,
        tokenNonce: action.payload,
      };
    default:
      return state;
  }
}
