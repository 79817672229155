const initialState = {
  paramProductCategory: "",
  dataCities: [],
  dataProducts: [],
  dataProvinces: [],
  dataStores: [],
  detailProduct: {},
  dataPagination: {},
  dataPaginationToko: {},
  dataPaginationDetailToko: {},
  isLoading: false,
  isError: false,
  errorMessage: "",
  dataDetailToko: [],
  dataUlasan: {},
  dataToko: {},
  dataPaginationUlasan: {},
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CATEGORY_PRODUCT":
      return {
        ...state,
        paramProductCategory: action.payload,
      };
    case "GET_PRODUCTS_START":
      return {
        ...state,
        dataCities: [],
        dataProducts: [],
        dataProvinces: [],
        dataStores: [],
        dataPagination: {},
        isLoading: true,
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        dataPagination: action.payload.dataProducts.pagination,
        dataProducts: action.payload.dataProducts.data,
        dataProvinces: action.payload.dataProvinces,
        dataStores: [],
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_PRODUCTS_FAILED":
      return {
        ...state,
        dataCities: [],
        dataProducts: [],
        dataProvinces: [],
        dataStores: [],
        dataPagination: {},
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "GET_CITIES_START":
      return {
        ...state,
        dataCities: [],
        isLoading: true,
      };
    case "GET_CITIES_SUCCESS":
      return {
        ...state,
        dataCities: action.payload,
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_CITIES_FAILED":
      return {
        ...state,
        dataCities: [],
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "GET_FILTER_START":
      return {
        ...state,
        dataPagination: {},
        dataProducts: [],
        dataStores: [],
        isLoading: true,
      };
    case "GET_FILTER_SUCCESS":
      return {
        ...state,
        dataPagination: action.payload.data.pagination,
        dataProducts: action.payload.data.data,
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_FILTER_FAILED":
      return {
        ...state,
        dataPagination: {},
        dataProducts: [],
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "GET_SEARCH_START":
      return {
        ...state,
        dataPagination: {},
        dataPaginationToko: {},
        dataProducts: [],
        dataStores: [],
        isLoading: true,
      };
    case "GET_SEARCH_SUCCESS":
      return {
        ...state,
        dataPagination: action.payload.dataProducts.pagination,
        dataProducts: action.payload.dataProducts.data,
        dataStores: action.payload.dataStores.data,
        dataPaginationToko: action.payload.dataStores.pagination,
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_SEARCH_FAILED":
      return {
        ...state,
        dataPagination: {},
        dataPaginationToko: {},
        dataProducts: [],
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "GET_DETAIL_PRODUCT_START":
      return {
        ...state,
        detailProduct: {},
        isLoading: true,
      };
    case "GET_DETAIL_PRODUCT_SUCCESS":
      return {
        ...state,
        detailProduct: action.payload,
        isLoading: false,
        isError: false,
        errorMessage: "",
      };
    case "GET_DETAIL_PRODUCT_FAILED":
      return {
        ...state,
        detailProduct: {},
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case "GET_DETAIL_TOKO_START":
      return {
        ...state,
        dataDetailToko: [],
        dataPaginationDetailToko: {},
        isLoading: true,
      };
    case "GET_DETAIL_TOKO_SUCCESS":
      return {
        ...state,
        dataDetailToko: action.payload.dataDetailToko.data,
        dataPaginationDetailToko: action.payload.dataDetailToko.pagination,
        isLoading: false,
      };
    case "GET_DETAIL_TOKO_FAILED":
      return {
        ...state,
        dataDetailToko: [],
        dataPaginationDetailToko: {},
        isLoading: true,
      };

    // 
    case "GET_ULASAN_START":
      return {
        ...state,
        dataUlasan: {},
        dataPaginationUlasan: {},
        isLoading: true,
      };
    case "GET_ULASAN_SUCCESS":
      return {
        ...state,
        dataUlasan: action.payload.dataUlasan.data,
        dataPaginationUlasan: action.payload.dataUlasan.pagination,
        isLoading: false,
      };
    case "GET_ULASAN_FAILED":
      return {
        ...state,
        dataUlasan: {},
        dataPaginationUlasan: {},
        isLoading: true,
      };
    // info 
    case "GET_TOKO_START":
      return {
        ...state,
        dataToko: {},
        isLoading: true,
      };
    case "GET_TOKO_SUCCESS":
      return {
        ...state,
        dataToko: action.payload,
        isLoading: false,
      };
    case "GET_TOKO_FAILED":
      return {
        ...state,
        dataToko: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
