import { MailFilled, WhatsAppOutlined } from "@ant-design/icons";
import moment from "moment";

import logoPartoHorizontalWhite from "../assets/img/img-logo-parto-horizontal-white-color.png";

function Footer() {
  const currentYear = moment().year();

  return (
    <div className="text-[#FAFAFA]">
      <div className="my-10">
        <img src={logoPartoHorizontalWhite} alt="Logo Parto" className="w-36" />
      </div>
      <div className="flex flex-row items-center justify-between mt-10 mb-16 w-full">
        <div>
          <h1 className="font-semibold text-base mb-2">Hubungi Kami</h1>
          <p className="text-sm">
            Gedung Mahligai 9, Lt. 6 #608
            <br /> Jl. A. Yani No. 18, Telanaipura, Jambi
            <br /> <MailFilled />{" "}
            <a href="mailto:mail@parto.id">mail@parto.id</a>
            <br /> <WhatsAppOutlined />{" "}
            <span
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(`https://wa.me/6282373055879`, "_blank");
              }}
            >
              +62 823-7305-5879
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
