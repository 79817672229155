export function formatRupiah(value) {
  if (value === null || value === undefined) {
    return "Rp0";
  }
  let roundValue = Math.round(value);

  const reverseValue = roundValue.toString().split("").reverse().join("");
  const thousand = reverseValue.match(/\d{1,3}/g);
  const result = thousand.join(".").split("").reverse().join("");
  return `Rp${result}`;
}

export function formatDate(date) {
  const dateObject = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };

  const formattedDate = dateObject.toLocaleDateString("id-ID", options);
  return formattedDate;
}

const currencyCode = "Rp "; // IDR or USD
const currencyPosition = "left"; // left or right
const maxFractionDigits = 2;
const thousandSeparator = ".";

function position(curPos, value) {
  return currencyPosition === "left"
    ? `${currencyCode}${value}`
    : `${value}${currencyCode}`;
}

export const CurrencyFormatter = (value) => {
  if (!value) return "Rp0";
  let result;

  const currencyCheck = currencyCode.replace(/\s/g, "").toLowerCase();
  if (currencyCheck === "idr" || currencyCheck === "rp") {
    value = Math.round(value);
  }

  const valueSplit = String(value.toFixed(maxFractionDigits)).split(
    `${thousandSeparator}`
  );
  const firstvalue = valueSplit[0];
  const secondvalue = valueSplit[1];
  const valueReal = String(firstvalue).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    `${thousandSeparator}`
  );

  if (Number(secondvalue) > 0) {
    result = position(
      currencyPosition,
      `${valueReal}${thousandSeparator}${secondvalue}`
    );
  } else {
    result = position(currencyPosition, `${valueReal}`);
  }

  return result;
};

export const ThousandSeparatorDot = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const ThousandSeparatorComma = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function DeleteCityName(inputString) {
  const regex = /\b(kabupaten|kota)\b/gi;

  const resultString = inputString.replace(regex, "");
  return resultString.replace(/\s{2,}/g, " ").trim();
}

export const formatNPWP = (value) => {
  if (typeof value === "string") {
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
      "$1.$2.$3.$4-$5.$6"
    );
  }
};
