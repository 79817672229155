import axios from "axios";

import { base_api } from "../../services/api";

export const getListNego = (token, params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_NEGO_START" });
      const axiosConfigGetListNego = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      };

      const responseGetListNego = await axios.get(
        `${base_api}lkpp/nego/get-all`,
        axiosConfigGetListNego
      );

      dispatch({
        type: "GET_LIST_NEGO_SUCCESS",
        payload: {
          listNego: responseGetListNego.data.data.data,
          totalListNego: responseGetListNego.data.data.pagination.totalElement,
          paginationListNego: responseGetListNego.data.data.pagination,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_LIST_NEGO_FAILED",
        payload: error.message,
      });
    }
  };
};

export const getDetailNego = (token, idSessionNego) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_DETAIL_NEGO_START" });
      const axiosConfigGetDetailNego = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responseGetDetailNego = await axios.get(
        `${base_api}lkpp/nego/get/${idSessionNego}`,
        axiosConfigGetDetailNego
      );
      const sortedById = responseGetDetailNego.data.data.sort(
        (a, b) => b.idNego - a.idNego
      );

      dispatch({
        type: "GET_DETAIL_NEGO_SUCCESS",
        payload: sortedById,
      });
    } catch (error) {
      dispatch({
        type: "GET_DETAIL_NEGO_FAILED",
        payload: error.message,
      });
    }
  };
};

export const submitNego = (
  token,
  idCart,
  isFor,
  qty = "",
  notes = "",
  nego_price = "",
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PUT_SUBMIT_NEGO_START" });

      const formData = new FormData();
      formData.append("qty", qty);
      formData.append("catatan", notes);
      formData.append("harga_nego", nego_price);

      const axiosConfigPutSubmitNego = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responsePutSubmitNego = await axios.put(
        `${base_api}nego/update-product-nego/${idCart}`,
        formData,
        axiosConfigPutSubmitNego
      );

      if (responsePutSubmitNego.data.status && callback) {
        callback();
      }

      if (isFor === "prices") {
        dispatch({
          type: "PUT_SUBMIT_NEGO_PRICES_DONE",
          payload: {
            isError: !responsePutSubmitNego.data.status,
            message: responsePutSubmitNego.data.message,
          },
        });
      } else if (isFor === "notes") {
        dispatch({
          type: "PUT_SUBMIT_NEGO_NOTES_DONE",
          payload: {
            isError: !responsePutSubmitNego.data.status,
            message: responsePutSubmitNego.data.message,
          },
        });
      } else {
        dispatch({
          type: "PUT_SUBMIT_NEGO_DONE",
          payload: {
            isError: !responsePutSubmitNego.data.status,
            message: responsePutSubmitNego.data.message,
          },
        });
      }
    } catch (error) {
      if (isFor === "prices") {
        dispatch({
          type: "PUT_SUBMIT_NEGO_PRICES_DONE",
          payload: {
            isError: true,
            message: error.message,
          },
        });
      } else if (isFor === "notes") {
        dispatch({
          type: "PUT_SUBMIT_NEGO_NOTES_DONE",
          payload: {
            isError: true,
            message: error.message,
          },
        });
      } else {
        dispatch({
          type: "PUT_SUBMIT_NEGO_DONE",
          payload: {
            isError: true,
            message: error.message,
          },
        });
      }
    }
  };
};

export const getListTransactions = (token, statusProcess, queryParams) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_TRANSACTION_START" });
      const axiosConfigGetListTransactions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      };

      const responseGetListTransactions = await axios.get(
        `${base_api}lkpp/transactions?proses=${statusProcess}`,
        axiosConfigGetListTransactions
      );

      if (statusProcess === 0) {
        dispatch({
          type: "GET_LIST_TRANSACTION_PENDING_SUCCESS",
          payload: {
            list: responseGetListTransactions.data.data.data,
            totalItem:
              responseGetListTransactions.data.data.pagination.totalElement,
          },
        });
      } else if (statusProcess === 1) {
        dispatch({
          type: "GET_LIST_TRANSACTION_PROCESSED_SUCCESS",
          payload: {
            list: responseGetListTransactions.data.data.data,
            totalItem:
              responseGetListTransactions.data.data.pagination.totalElement,
          },
        });
      } else if (statusProcess === 3) {
        dispatch({
          type: "GET_LIST_TRANSACTION_SENDING_SUCCESS",
          payload: {
            list: responseGetListTransactions.data.data.data,
            totalItem:
              responseGetListTransactions.data.data.pagination.totalElement,
          },
        });
      } else if (statusProcess === 4) {
        dispatch({
          type: "GET_LIST_TRANSACTION_DONE_SUCCESS",
          payload: {
            list: responseGetListTransactions.data.data.data,
            totalItem:
              responseGetListTransactions.data.data.pagination.totalElement,
          },
        });
      } else if (statusProcess === "x") {
        dispatch({
          type: "GET_LIST_TRANSACTION_CANCEL_SUCCESS",
          payload: {
            list: responseGetListTransactions.data.data.data,
            totalItem:
              responseGetListTransactions.data.data.pagination.totalElement,
          },
        });
      }
    } catch (error) {
      if (statusProcess === 0) {
        dispatch({
          type: "GET_LIST_TRANSACTION_PENDING_ERROR",
          payload: error.message,
        });
      } else if (statusProcess === 1) {
        dispatch({
          type: "GET_LIST_TRANSACTION_PROCESSED_ERROR",
          payload: error.message,
        });
      } else if (statusProcess === 3) {
        dispatch({
          type: "GET_LIST_TRANSACTION_SENDING_ERROR",
          payload: error.message,
        });
      } else if (statusProcess === 4) {
        dispatch({
          type: "GET_LIST_TRANSACTION_DONE_ERROR",
          payload: error.message,
        });
      } else if (statusProcess === "x") {
        dispatch({
          type: "GET_LIST_TRANSACTION_CANCEL_ERROR",
          payload: error.message,
        });
      }
    }
  };
};

export const getDetailTransaction = (token, idTransaction) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_DETAIL_TRANSACTION_START" });
      const axiosConfigGetDetailTransaction = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responseGetDetailTransaction = await axios.get(
        `${base_api}lkpp/transactions/${idTransaction}`,
        axiosConfigGetDetailTransaction
      );

      dispatch({
        type: "GET_DETAIL_TRANSACTION_SUCCESS",
        payload: responseGetDetailTransaction.data.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_DETAIL_TRANSACTION_ERROR",
        payload: error.message,
      });
    }
  };
};

export const addNego = (
  token,
  isNego,
  idSatker,
  idInstansi,
  idBidang,
  namaSatker,
  namaInstansi,
  namaBidang,
  idPptk,
  idPpk,
  npwpInstansi,
  pajakDaerah,
  deadline,
  keperluan,
  namaPenerima,
  noHpPenerima,
  alamat,
  alamatLengkap,
  metodePembayaran,
  mekanismePembayaran,
  top,
  pph,
  subKategori,
  bank,
  tokenNonce
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PUT_ADD_TRANSACTION_START" });

      const formDataProposeNego = new FormData();
      formDataProposeNego.append("id_satker", idSatker);
      formDataProposeNego.append("id_instansi", idInstansi);
      formDataProposeNego.append("id_bidang", idBidang);
      formDataProposeNego.append("nama_satker", namaSatker);
      formDataProposeNego.append("nama_instansi", namaInstansi);
      formDataProposeNego.append("nama_bidang", namaBidang);
      formDataProposeNego.append("id_pptk", idPptk);
      formDataProposeNego.append("id_ppk", idPpk);
      formDataProposeNego.append("npwp_instansi", npwpInstansi);
      formDataProposeNego.append("pajak_daerah", pajakDaerah);
      formDataProposeNego.append("deadline", deadline);
      formDataProposeNego.append("keperluan", keperluan);
      formDataProposeNego.append("nama_penerima", namaPenerima);
      formDataProposeNego.append("no_hp_penerima", noHpPenerima);
      formDataProposeNego.append("alamat", alamat);
      formDataProposeNego.append("alamat_lengkap", alamatLengkap);
      formDataProposeNego.append("metode_pembayaran", metodePembayaran);
      formDataProposeNego.append("mekanisme_pembayaran", mekanismePembayaran);
      formDataProposeNego.append("top", top);
      formDataProposeNego.append("pph", pph);
      formDataProposeNego.append("sub_kategori", subKategori);
      formDataProposeNego.append("bank", bank);
      formDataProposeNego.append("token_nonce", tokenNonce);

      const formDataCreateTransaction = new FormData();
      formDataCreateTransaction.append("alamat_lengkap", alamatLengkap);
      formDataCreateTransaction.append("subdistrict_id", alamat);
      formDataCreateTransaction.append("pph", pph);
      formDataCreateTransaction.append("npwp_instansi", npwpInstansi);
      formDataCreateTransaction.append("nama_penerima", namaPenerima);
      formDataCreateTransaction.append("pajak_daerah", pajakDaerah);
      formDataCreateTransaction.append("no_hp_penerima", noHpPenerima);
      formDataCreateTransaction.append("nama_satker", namaSatker);
      formDataCreateTransaction.append("nama_bidang", namaBidang);
      formDataCreateTransaction.append("id_instansi", idInstansi);
      formDataCreateTransaction.append("id_satker", idSatker);
      formDataCreateTransaction.append("keperluan", keperluan);
      formDataCreateTransaction.append("id_bidang", idBidang);
      formDataCreateTransaction.append("metode_pembayaran", metodePembayaran);
      formDataCreateTransaction.append("nama_instansi", namaInstansi);
      formDataCreateTransaction.append(
        "mekanisme_pembayaran",
        mekanismePembayaran
      );
      formDataCreateTransaction.append("id_pptk", idPptk);
      formDataCreateTransaction.append("id_ppk", idPpk);
      formDataCreateTransaction.append("deadline", deadline);
      formDataCreateTransaction.append("sub_kategori", subKategori);
      formDataCreateTransaction.append("top", top);
      formDataCreateTransaction.append("bank", bank);
      formDataCreateTransaction.append("token_nonce", tokenNonce);

      const axiosConfigPutAddNego = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (isNego) {
        const responsePutAddNego = await axios.put(
          `${base_api}nego/add-nego`,
          formDataProposeNego,
          axiosConfigPutAddNego
        );

        console.log(responsePutAddNego.data.data);
        console.log("responsePutAddNego.data.data.session");
        console.log(responsePutAddNego.data.data.session);
        dispatch({
          type: "PUT_ADD_TRANSACTION_DONE",
          payload: {
            type: "nego",
            lastestId: responsePutAddNego.data.data.session,
            isError: !responsePutAddNego.data.status,
            message: responsePutAddNego.data.message,
          },
        });
      } else if (!isNego) {
        const responsePutAddNego = await axios.put(
          `${base_api}b2g/transaction/create-penjualan`,
          formDataCreateTransaction,
          axiosConfigPutAddNego
        );
        console.log(responsePutAddNego.data.data);
        console.log("responsePutAddNego.data.data.last_id_penjualan");
        console.log(responsePutAddNego.data.data.last_id_penjualan);

        dispatch({
          type: "PUT_ADD_TRANSACTION_DONE",
          payload: {
            type: "create",
            lastestId: responsePutAddNego.data.data.last_id_penjualan,
            isError: !responsePutAddNego.data.status,
            message: responsePutAddNego.data.message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "PUT_ADD_TRANSACTION_DONE",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const cancelTransaction = (
  token,
  idTransaction,
  cancellationReason,
  fnRefreshTransactionDetail
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CANCEL_TRANSACTION_START" });

      const formData = new FormData();
      formData.append("id_penjualan", idTransaction);
      formData.append("alasan_pembatalan", cancellationReason);

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${base_api}b2g/transaction/pengajuan-pembatalan`,
        formData,
        axiosConfig
      );

      dispatch({
        type: "CANCEL_TRANSACTION_SUCCESS",
        payload: {
          isError: !response.data.status,
          message: response.data.message,
        },
      });
      fnRefreshTransactionDetail(token, idTransaction);
    } catch (error) {
      dispatch({
        type: "CANCEL_TRANSACTION_FAILED",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const getListRequestRevision = (token, params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_REVISION_START" });
      const axiosConfigGetListRevision = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      };

      const responseGetListRevision = await axios.get(
        `${base_api}lkpp/transactions/revisions`,
        axiosConfigGetListRevision
      );
      dispatch({
        type: "GET_LIST_REVISION_SUCCESS",
        payload: {
          listRequestRevision: responseGetListRevision.data.data.data,
          totalListRequestRevision:
            responseGetListRevision.data.data.pagination.totalElement,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_LIST_REVISION_FAILED",
        payload: error.message,
      });
    }
  };
};

export const getListRequestCancellation = (token, params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_CANCELLATION_START" });
      const axiosConfigGetListCancellation = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      };

      const responseGetListCancellation = await axios.get(
        `${base_api}lkpp/transactions/cancelled`,
        axiosConfigGetListCancellation
      );
      dispatch({
        type: "GET_LIST_CANCELLATION_SUCCESS",
        payload: {
          listRequestCancellation: responseGetListCancellation.data.data.data,
          totalListRequestCancellation:
            responseGetListCancellation.data.data.pagination.totalElement,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_LIST_CANCELLATION_FAILED",
        payload: error.message,
      });
    }
  };
};

export const getFieldList = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LIST_FIELD_START" });
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.get(
        `${base_api}lkpp/transactions/bidang`,
        axiosConfig
      );
      let listField = res.data?.data?.map((el, i) => ({
        value: i + 1,
        label: el.namaBidang,
      }));
      dispatch({
        type: "GET_LIST_FIELD_SUCCESS",
        payload: {
          listField: listField,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_LIST_FIELD_FAILED",
        payload: error.message,
      });
    }
  };
};

export const submitRenego = (token, lastIdNego) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "PUT_RENEGO_START" });

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `${base_api}nego/ajuan-ulang-nego/${lastIdNego}`,
        {},
        axiosConfig
      );

      dispatch({
        type: "PUT_RENEGO_SUCCESS",
        payload: res.data.data.last_id_nego,
      });
    } catch (error) {
      dispatch({
        type: "PUT_RENEGO_ERROR",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const getRenego = (token, newIdNego) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_RENEGO_START" });

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `${base_api}nego/edit-nego/${newIdNego}`,
        axiosConfig
      );

      dispatch({
        type: "GET_RENEGO_SUCCESS",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_RENEGO_ERROR",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const submitProductRenego = (token, hargaNego, idCart) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SUBMIT_PRODUCT_RENEGO_START" });

      const formData = new FormData();
      formData.append("harga_nego", hargaNego);

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": "multipart/form-data",
      };
      const res = await axios.put(
        `${base_api}nego/update-product-nego/${idCart}`,
        formData,
        axiosConfig
      );

      dispatch({
        type: "SUBMIT_PRODUCT_RENEGO_SUCCESS",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "SUBMIT_PRODUCT_RENEGO_ERROR",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const submitFinalRenego = (token, newIdNego) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SUBMIT_FINAL_RENEGO_START" });

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${base_api}nego/update-status-nego/${newIdNego}`,
        {},
        axiosConfig
      );

      dispatch({
        type: "SUBMIT_FINAL_RENEGO_SUCCESS",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "SUBMIT_FINAL_RENEGO_ERROR",
        payload: {
          isError: true,
          message: error.message,
        },
      });
    }
  };
};

export const setActiveTab = (activeTab) => {
  return async (dispatch) => {
    dispatch({ type: "SET_ACTIVE_TAB", activeTab });
  };
};

export const resetLastestId = () => {
  return async (dispatch) => {
    dispatch({ type: "RESET_LASTEST_ID" });
  };
};
