import { FloatButton, Layout, theme } from "antd";

import { WhatsAppOutlined } from "@ant-design/icons";
import { Outlet, useLocation } from "react-router-dom";
import FooterContent from "./footer";
import HeaderContent from "./header";

const { Header, Content, Footer } = Layout;

const BaseLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const location = useLocation();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{ background: colorBgContainer }}
        className="sticky top-0 z-50"
      >
        <HeaderContent />
      </Header>
      <Content style={{ background: colorBgContainer }} className="py-2">
        <Outlet />
      </Content>
      {(location.pathname === "/produk" ||
        location.pathname === "/transaksi") && (
        <>
          <Footer style={{ background: "#04493B" }}>
            <FooterContent />
          </Footer>

          <FloatButton
            icon={<WhatsAppOutlined />}
            type="primary"
            style={{
              right: 24,
            }}
            tooltip={<div>Butuh bantuan?</div>}
            onClick={() => {
              window.open(`https://wa.me/6282373055879`, "_blank");
            }}
          />
        </>
      )}
    </Layout>
  );
};

export default BaseLayout;
