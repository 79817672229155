const initialState = {
  isAlreadyFirstFetch: false,
  activeTab: "1",
  detailNego: [],
  newIdNego: 0,
  detailTransaction: {},
  listProductRenego: [],
  listLocation: [],
  listBank: [],
  listField: [],
  listNego: [],
  listTransactionPending: [],
  listTransactionProcessed: [],
  listTransactionSending: [],
  listTransactionDone: [],
  listTransactionCancel: [],
  listRequestRevision: [],
  listRequestCancellation: [],
  totalListNego: 0,
  totalListTransactionPending: 0,
  totalListTransactionProcessed: 0,
  totalListTransactionSending: 0,
  totalListTransactionDone: 0,
  totalListTransactionCancel: 0,
  totalListRequestRevision: 0,
  totalListRequestCancellation: 0,
  paginationListNego: {},
  isLoading: false,
  isError: false,
  isErrorAddTransaction: false,
  isErrorSubmitPricesNego: false,
  isErrorSubmitNotesNego: false,
  isLoadingModal: false,
  isErrorCancellationTransaction: false,
  isSuccessCancelTransaction: false,
  isSuccessSubmitFinal: false,
  errorMessage: "",
  message: "",
  transactionType: "",
  lastestId: 0,
};

export default function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LIST_FIELD_START":
      return { ...state };
    case "GET_LIST_FIELD_SUCCESS":
      return {
        ...state,
        isError: false,
        listField: action.payload.listField,
      };
    case "GET_LIST_FIELD_ERROR":
      return {
        ...state,
        isError: true,
      };
    case "GET_LIST_NEGO_START":
      return { ...state, isLoading: true };
    case "GET_LIST_NEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listNego: action.payload.listNego,
        totalListNego: action.payload.totalListNego,
        paginationListNego: action.payload.paginationListNego,
      };
    case "GET_LIST_NEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
        listNego: [],
        totalListNego: 0,
        paginationListNego: {},
      };
    case "GET_DETAIL_NEGO_START":
      return {
        ...state,
        newIdNego: 0,
        isLoading: true,
      };
    case "GET_DETAIL_NEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        detailNego: action.payload,
        isSuccessSubmitFinal: false,
      };
    case "GET_DETAIL_NEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        detailNego: [],
        errorMessage: action.payload,
        isSuccessSubmitFinal: false,
      };
    case "PUT_SUBMIT_NEGO_START":
      return { ...state, isLoading: true };
    case "PUT_SUBMIT_NEGO_PRICES_DONE":
      return {
        ...state,
        isLoading: false,
        isErrorSubmitPricesNego: action.payload.isError,
        message: action.payload.message,
      };
    case "PUT_SUBMIT_NEGO_NOTES_DONE":
      return {
        ...state,
        isLoading: false,
        isErrorSubmitNotesNego: action.payload.isError,
        message: action.payload.message,
      };
    case "PUT_SUBMIT_NEGO_DONE":
      return {
        ...state,
        isLoading: false,
        isError: action.payload.isError,
        message: action.payload.message,
      };
    case "GET_LIST_TRANSACTION_START":
      return {
        ...state,
        isAlreadyFirstFetch: true,
        isLoading: true,
      };
    case "GET_LIST_TRANSACTION_PENDING_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listTransactionPending: action.payload.list,
        totalListTransactionPending: action.payload.totalItem,
      };
    case "GET_LIST_TRANSACTION_PENDING_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listTransactionPending: [],
        totalListTransactionPending: 0,
        errorMessage: action.payload,
      };
    case "GET_LIST_TRANSACTION_PROCESSED_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listTransactionProcessed: action.payload.list,
        totalListTransactionProcessed: action.payload.totalItem,
      };
    case "GET_LIST_TRANSACTION_PROCESSED_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listTransactionProcessed: action.payload.list,
        totalListTransactionProcessed: action.payload.totalItem,
        errorMessage: action.payload,
      };
    case "GET_LIST_TRANSACTION_SENDING_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listTransactionSending: action.payload.list,
        totalListTransactionSending: action.payload.totalItem,
      };
    case "GET_LIST_TRANSACTION_SENDING_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listTransactionSending: [],
        totalListTransactionSending: 0,
        errorMessage: action.payload,
      };
    case "GET_LIST_TRANSACTION_DONE_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listTransactionDone: action.payload.list,
        totalListTransactionDone: action.payload.totalItem,
      };
    case "GET_LIST_TRANSACTION_DONE_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listTransactionDone: [],
        totalListTransactionDone: 0,
        errorMessage: action.payload,
      };
    case "GET_LIST_TRANSACTION_CANCEL_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listTransactionCancel: action.payload.list,
        totalListTransactionCancel: action.payload.totalItem,
      };
    case "GET_LIST_TRANSACTION_CANCEL_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listTransactionCancel: [],
        totalListTransactionCancel: 0,
        errorMessage: action.payload,
      };
    case "GET_LIST_REVISION_START":
      return { ...state, isLoading: true };
    case "GET_LIST_REVISION_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listRequestRevision: action.payload.listRequestRevision,
        totalListRequestRevision: action.payload.totalListRequestRevision,
      };
    case "GET_LIST_REVISION_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listRequestRevision: [],
        errorMessage: action.payload,
        totalListRequestRevision: 0,
      };
    case "GET_LIST_CANCELLATION_START":
      return { ...state, isLoading: true };
    case "GET_LIST_CANCELLATION_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listRequestCancellation: action.payload.listRequestCancellation,
        totalListRequestCancellation:
          action.payload.totalListRequestCancellation,
      };
    case "GET_LIST_CANCELLATION_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listRequestCancellation: [],
        errorMessage: action.payload,
        totalListRequestCancellation: 0,
      };
    case "GET_DETAIL_TRANSACTION_START":
      return { ...state, isLoading: true };
    case "GET_DETAIL_TRANSACTION_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        detailTransaction: action.payload,
      };
    case "GET_DETAIL_TRANSACTION_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        detailTransaction: {},
        errorMessage: action.payload,
      };
    case "PUT_ADD_TRANSACTION_START":
      return { ...state, isLoading: true, lastestId: 0 };
    case "PUT_ADD_TRANSACTION_DONE":
      console.log(action.payload);
      return {
        ...state,
        transactionType: action.payload.type,
        lastestId: action.payload.lastestId,
        isLoading: false,
        isErrorAddTransaction: action.payload.isError,
        message: action.payload.message,
      };

    case "CANCEL_TRANSACTION_START":
      return {
        ...state,
        isLoadingModal: true,
      };
    case "CANCEL_TRANSACTION_SUCCESS":
      return {
        ...state,
        isLoadingModal: false,
        isSuccessCancelTransaction: false,
        isErrorCancellationTransaction: false,
      };
    case "CANCEL_TRANSACTION_FAILED":
      return {
        ...state,
        isLoadingModal: false,
        isErrorCancellationTransaction: true,
        isSuccessCancelTransaction: false,
        errorMessage: action.payload,
      };
    case "GET_LIST_BANK_START":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_LIST_BANK_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listBank: action.payload.data,
      };
    case "GET_LIST_BANK_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listBank: [],
        errorMessage: action.payload.message,
      };
    case "GET_LIST_LOCATION_START":
      return {
        ...state,
        isLoading: true,
        listLocation: [],
      };
    case "GET_LIST_LOCATION_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listLocation: action.payload.data,
      };
    case "GET_LIST_LOCATION_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        listLocation: [],
        errorMessage: action.payload.message,
      };
    case "PUT_RENEGO_START":
      return { ...state, isLoading: true };
    case "PUT_RENEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        newIdNego: action.payload,
      };
    case "PUT_RENEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
      };
    case "GET_RENEGO_START":
      return { ...state, isLoading: true };
    case "GET_RENEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        listProductRenego: action.payload,
      };
    case "GET_RENEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
      };
    case "SUBMIT_PRODUCT_RENEGO_START":
      return { ...state, isLoading: true };
    case "SUBMIT_PRODUCT_RENEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
      };
    case "SUBMIT_PRODUCT_RENEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
      };
    case "SUBMIT_FINAL_RENEGO_START":
      return { ...state, isLoading: true, isSuccessSubmitFinal: false };
    case "SUBMIT_FINAL_RENEGO_SUCCESS":
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSuccessSubmitFinal: true,
      };
    case "SUBMIT_FINAL_RENEGO_ERROR":
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccessSubmitFinal: false,
        errorMessage: action.payload,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case "RESET_LASTEST_ID":
      return {
        ...state,
        lastestId: 0,
      };
    default:
      return state;
  }
}
