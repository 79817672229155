import axios from "axios";

import { base_api, api_key } from "../../services/api";
import jwtDecode from "jwt-decode";

export const validateToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "VALIDATE_START" });
      const axiosConfigValidateToken = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Api-Key": api_key,
        },
      };
      const responseValidateToken = await axios.get(
        `${base_api}sso/verify-token`,
        axiosConfigValidateToken
      );
      dispatch({
        type: "VALIDATE_SUCCESS",
        payload: responseValidateToken.data.status,
      });
    } catch (error) {
      dispatch({ type: "VALIDATE_ERROR", payload: error.message });
    }
  };
};

export const decodeBase64 = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "STORE_TOKEN_NONCE", payload: data });
      dispatch({ type: "DECODE_START" });
      const decodeStringResult = await JSON.parse(atob(data));
      const decodeAccessToken = jwtDecode(decodeStringResult.accessToken);

      dispatch({ type: "DECODE_ACCESS_TOKEN", payload: decodeAccessToken });
      dispatch({ type: "DECODE_SUCCESS", payload: decodeStringResult });
    } catch (error) {
      dispatch({ type: "DECODE_ERROR", payload: error.message });
    }
  };
};
